module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-26065780-15"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kris Chase | VP Of Technology","short_name":"Kris Chase","start_url":"/","background_color":"#f7f17e","theme_color":"#f7f17e","display":"standalone","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-wordpress-inline-images/gatsby-browser.js'),
      options: {"plugins":[],"baseUrl":"krischase.com","protocol":"https"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
